import axios from 'axios'
import axios1 from '@/config/httpConfig'
// 无token获取发票预览
export function getTemplatePdfNoToken(v) {
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/cpq/template/getTemplatePdfNoToken`, v)
}
// 下载附件
export function downloadFileNoToken(v) {
    return axios.get(`${window.Glod['ccex-apitsf']+'/api'}/file/downloadFileNoToken`, v)
}
// 无token查询发票在线支付配置  
export function getEmailOnlinePaySetUpNotoken(v) {
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/emailonlinepaysetup/getEmailOnlinePaySetUpNotoken`, v)
}
// 支付宝/微信/paypol支付接口
export function creatOrder(v) {
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/emailonlinepay/creatOrder`, v)
}
// visa支付
export function visaPay(v) {
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/emailonlinepay/visaPay`, v)
}
// 轮询查询支付状态
export function getPayStatus(v) {
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/emailonlinepay/getPayStatus`, v)
}
// paypal查询支付状态
// 根据发票的id获取邮件所需信息无token
export function GetMessageByNumNoToken(v) {
    return axios.post(`${window.Glod['ccex-apitsf']+'/api'}/emailonlinepay/GetMessageByNumNoToken`, v)
}
// 查询发票在线支付配置
export function getEmailOnlinePaySetUp(v) {
    return axios1.get('/emailonlinepaysetup/getEmailOnlinePaySetUp', v)
}
// 更新支付方式配置
export function updateEmailOnlinePaySetUp(v) {
    return axios1.post('/emailonlinepaysetup/updateEmailOnlinePaySetUp', v)
}
