<template>
  <div class="inline-pay">
    <!-- 顶部开关 -->
    <div class="switch">
      <div class="switchL">
        <svg class="zaixianzhifu" aria-hidden="true">
          <use href="#icon-zaixianzhifu"></use>
        </svg>
        <div>
          <span>{{ $t("front-invoice-online-payment-setup-title") }}</span>
          <p>{{ $t("front-invoice-online-payment-setup-describe") }}</p>
        </div>
      </div>
      <div class="switchR">
        <span>{{ state }}</span>
        <el-switch v-model="value" @change="changeState(value, 'index')">
        </el-switch>
      </div>
    </div>
    <!-- 配置 -->
    <div class="configuration" v-if="value">
      <!-- 适用于全球客户 -->
      <div>
        <span>
          <svg class="kehu" aria-hidden="true">
            <use href="#icon-quanqiukehu"></use>
          </svg>
          <b>{{ $t("front-invoice-online-payment-setup-global") }}</b>
        </span>
        <div>
          <div
            class="configurationItem"
            v-for="(item, index) in configurationList"
            :key="index"
            v-show="index > 1"
          >
            <div class="item-top">
              <svg
                :class="item.name == 'Visa' ? 'dialogSvgVisa' : 'dialogSvg'"
                aria-hidden="true"
              >
                <use :href="item.img"></use>
              </svg>
              <div class="item-switch">
                <span>{{ item.state }}</span>
                <el-switch
                  v-model="item.value"
                  @change="changeState(item.value, index, item.name)"
                >
                </el-switch>
              </div>
            </div>
            <span class="item-Tips">
              <!-- 点击“立即配置”，配置{{ item.name }}支付接口。 -->
              {{
                $t("front-invoice-online-payment-setup-configure", {
                  name: item.name,
                })
              }}
            </span>
            <el-button type="primary" @click="configureNow(item.name, index)">{{
              $t("front-invoice-online-payment-setup-configure-button")
            }}</el-button>
          </div>
        </div>
      </div>
      <!-- 适用于中国客户 -->
      <div>
        <span>
          <svg class="kehu" aria-hidden="true">
            <use href="#icon-zhongguokehu"></use>
          </svg>
          <b>{{ $t("front-invoice-online-payment-setup-china") }}</b>
        </span>
        <div>
          <div
            class="configurationItem"
            v-for="(item, index) in configurationList"
            :key="index"
            v-show="index < 2"
          >
            <div class="item-top">
              <svg class="dialogSvg" aria-hidden="true">
                <use :href="item.img"></use>
              </svg>
              <div class="item-switch">
                <span>{{ item.state }}</span>
                <el-switch
                  v-model="item.value"
                  @change="changeState(item.value, index, item.name)"
                >
                </el-switch>
              </div>
            </div>
            <span class="item-Tips">
              {{
                $t("front-invoice-online-payment-setup-configure", {
                  name: item.name,
                })
              }}
            </span>
            <el-button type="primary" @click="configureNow(item.name, index)">{{
              $t("front-invoice-online-payment-setup-configure-button")
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 配置弹框 -->
    <el-dialog
      :title="$t('front-invoice-online-payment-setup-configure-dialog')"
      :show-close="false"
      :visible.sync="dialogFormVisible"
    >
      <!-- 支付宝 -->
      <div v-show="isShowZ" class="dialog-content">
        <svg class="dialogSvg" aria-hidden="true">
          <use href="#icon-zhifubaozhifu"></use>
        </svg>
        <el-form
          :model="formZ"
          style="width: 514px"
          :rules="rulesZ"
          ref="formZ"
        >
          <el-form-item label="aliprivatekey" prop="aliprivatekey">
            <el-input v-model="formZ.aliprivatekey" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="alipublickey" prop="alipublickey">
            <el-input v-model="formZ.alipublickey" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="aliappid" prop="aliappid">
            <el-input v-model="formZ.aliappid" placeholder=""></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 微信 -->
      <div v-show="isShowW" class="dialog-content">
        <svg class="dialogSvg" aria-hidden="true">
          <use href="#icon-weixinzhifu"></use>
        </svg>
        <el-form
          :model="formW"
          style="width: 514px"
          :rules="rulesW"
          ref="formW"
        >
          <el-form-item label="wxsecret" prop="wxsecret">
            <el-input v-model="formW.wxsecret" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="wxappid" prop="wxappid">
            <el-input v-model="formW.wxappid" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="wxmchid" prop="wxmchid">
            <el-input v-model="formW.wxmchid" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="appsecret" prop="appsecret">
            <el-input v-model="formW.appsecret" placeholder=""></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- PayPal -->
      <div v-show="isShowP" class="dialog-content">
        <svg class="dialogSvg" aria-hidden="true">
          <use href="#icon-a-Paypal1"></use>
        </svg>
        <el-form
          :model="formP"
          style="width: 514px"
          :rules="rulesP"
          ref="formP"
        >
          <el-form-item label="clientid" prop="clientid">
            <el-input v-model="formP.clientid" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="clientsecret" prop="clientsecret">
            <el-input v-model="formP.clientsecret" placeholder=""></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- VISA -->
      <div v-show="isShowV" class="dialog-content">
        <svg class="dialogSvgV" aria-hidden="true">
          <use href="#icon-a-Visa1"></use>
        </svg>
        <el-form
          :model="formV"
          style="width: 514px"
          :rules="rulesV"
          ref="formV"
        >
          <el-form-item label="merchantID" prop="merchantID">
            <el-input v-model="formV.merchantID" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="merchantsecretKey" prop="merchantsecretKey">
            <el-input
              v-model="formV.merchantsecretKey"
              placeholder=""
            ></el-input>
          </el-form-item>
          <el-form-item label="merchantKeyId" prop="merchantKeyId">
            <el-input v-model="formV.merchantKeyId" placeholder=""></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">{{ $t("label_chatter_cancel") }}</el-button>
        <el-button type="primary" @click="submitForm">{{
          $t("label.userphoto.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import * as apis from "./api.js";
export default {
  name: "",
  data() {
    return {
      index0: "", //当前支付index
      onlinePayList: {}, //查询在线支付配置的数据
      value: "", //总开关状态
      state: this.$i18n.t("front-electronic-signature-isclose"), //总开关
      configurationList: [], //配置列表
      dialogFormVisible: false, //控制弹框显示隐藏
      formZ: {}, //支付宝弹框收集数据
      formW: {}, //微信弹框收集数据
      formP: {}, //paypal弹框收集数据
      formV: {}, //visa弹框收集数据
      rulesZ: {
        aliprivatekey: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
        alipublickey: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
        aliappid: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
      },
      rulesW: {
        wxsecret: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
        wxappid: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
        wxmchid: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
        appsecret: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
      },
      rulesP: {
        clientid: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
        clientsecret: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
      },
      rulesV: {
        merchantID: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
        merchantsecretKey: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
        merchantKeyId: [
          {
            required: true,
            message: this.$i18n.t("label_tabpage_placeenterz"),
            trigger: "blur",
          },
        ],
      },
      form: {},

      // 弹框显示内容
      isShowZ: false,
      isShowW: false,
      isShowP: false,
      isShowV: false,
    };
  },
  methods: {
    // 查询发票在线支付配置
    getEmailOnlinePaySetUp() {
      // let params = {};
      apis.getEmailOnlinePaySetUp().then((res) => {
        this.onlinePayList = res.data;
        // 

        this.configurationList = [
          {
            img: "#icon-zhifubaozhifu",
            name: "支付宝", //无需替换
            state: this.onlinePayList.isalipay == "true" ? this.$i18n.t('front-electronic-signature-isopen') : this.$i18n.t('front-electronic-signature-isclose'),    // 已开启  未开启
            value: this.onlinePayList.isalipay == "true" ? true : false,
          },
          {
            img: "#icon-weixinzhifu",
            name: "微信", //无需替换
            state: this.onlinePayList.iswxpay == "true" ? this.$i18n.t('front-electronic-signature-isopen') : this.$i18n.t('front-electronic-signature-isclose'),
            value: this.onlinePayList.iswxpay == "true" ? true : false,
          },
          {
            img: "#icon-a-Paypal1",
            name: "Paypal",
            state: this.onlinePayList.ispaypol == "true" ? this.$i18n.t('front-electronic-signature-isopen') : this.$i18n.t('front-electronic-signature-isclose'),
            value: this.onlinePayList.ispaypol == "true" ? true : false,
          },
          {
            img: "#icon-a-Visa1",
            name: "Visa",
            state: this.onlinePayList.isvisa == "true" ? this.$i18n.t('front-electronic-signature-isopen') : this.$i18n.t('front-electronic-signature-isclose'),
            value: this.onlinePayList.isvisa == "true" ? true : false,
          },
        ]; //配置列表

        //支付宝弹框收集数据
        //是不是中国国版判断
        this.formZ = {
          type: "alipay",
          isalipay: "true",
          aliprivatekey: this.onlinePayList.aliprivatekey,
          alipublickey: this.onlinePayList.alipublickey,
          aliappid: this.onlinePayList.aliappid,
        };

        //微信弹框收集数据
        this.formW = {
          type: "wxpay",
          iswxpay: "true",
          wxsecret: this.onlinePayList.wxsecret,
          wxappid: this.onlinePayList.wxappid,
          wxmchid: this.onlinePayList.wxmchid,
          appsecret: this.onlinePayList.appsecret,
        };

        //paypal弹框收集数据
        this.formP = {
          type: "paypol",
          ispaypol: "true",
          clientid: this.onlinePayList.clientid,
          clientsecret: this.onlinePayList.clientsecret,
        };

        //visa弹框收集数据
        this.formV = {
          type: "visa",
          isvisa: "true",
          merchantID: this.onlinePayList.merchantID,
          merchantsecretKey: this.onlinePayList.merchantsecretKey,
          merchantKeyId: this.onlinePayList.merchantKeyId,
        };
        this.value = this.onlinePayList.isonlinepay == "true" ? true : false; //总开关状态
        this.state =
          this.onlinePayList.isonlinepay == "true"
            ? this.$i18n.t("front-electronic-signature-isopen")
            : this.$i18n.t("front-electronic-signature-isclose");
      });
    },
    // 更新支付方式配置
    updateEmailOnlinePaySetUp(form) {
      // 
      let params = form;
      apis.updateEmailOnlinePaySetUp(params).then((res) => {
        // 
        if (res.returnCode != "1") {
          this.$message({
            showClose: true,
            message: this.$t("label_setup_failed"),
            type: "error",
            duration: 6000,
          });
        }
        this.getEmailOnlinePaySetUp();
      });
    },
    // 开启关闭按钮开关
    changeState(value, index, name) {
      // 
      this.index0 = index;
      // 
      if (index == "index") {
        if (value == true) {
          this.state = this.$i18n.t("front-electronic-signature-isopen");
        } else {
          this.state = this.$i18n.t("front-electronic-signature-isclose");
        }
        this.updateEmailOnlinePaySetUp({ isonlinepay: this.value });
      } else {
        if (value == true) {
          this.configurationList[index].state = this.$i18n.t(
            "front-electronic-signature-isopen"
          );
          this.configureNow(name, index);
        } else {
          this.configurationList[index].state = this.$i18n.t(
            "front-electronic-signature-isclose"
          );
        }

        // 关闭支付方式
        if (name == "支付宝") {
          this.form = this.formZ;
          this.form.isalipay = "false";
        } else if (name == "微信") {
          this.form = this.formW;
          this.form.iswxpay = "false";
        } else if (name == "Paypal") {
          this.form = this.formP;
          this.form.ispaypol = "false";
        } else if (name == "Visa") {
          this.form = this.formV;
          this.form.isvisa = "false";
        }
        this.updateEmailOnlinePaySetUp(this.form);
      }
    },
    // 立即配置按钮
    configureNow(name, index) {
      this.dialogFormVisible = true;
      this.index0 = index;
      if (name == "支付宝") {
        this.isShowZ = true;
        this.isShowW = false;
        this.isShowP = false;
        this.isShowV = false;
      } else if (name == "微信") {
        this.isShowZ = false;
        this.isShowW = true;
        this.isShowP = false;
        this.isShowV = false;
      } else if (name == "Paypal") {
        this.isShowZ = false;
        this.isShowW = false;
        this.isShowP = true;
        this.isShowV = false;
      } else if (name == "Visa") {
        this.isShowZ = false;
        this.isShowW = false;
        this.isShowP = false;
        this.isShowV = true;
      }
    },
    // 弹框表单验证
    submitForm() {
      let formName;
      if (this.isShowZ) {
        formName = "formZ";
      } else if (this.isShowW) {
        formName = "formW";
      } else if (this.isShowP) {
        formName = "formP";
      } else if (this.isShowV) {
        formName = "formV";
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save();
        } else {
          
          return false;
        }
      });
    },
    // 保存
    
    save() {
      this.dialogFormVisible = false;
      if (this.isShowZ) {
        this.form = this.formZ;
      } else if (this.isShowW) {
        this.form = this.formW;
      } else if (this.isShowP) {
        this.form = this.formP;
      } else if (this.isShowV) {
        this.form = this.formV;
      }
      this.updateEmailOnlinePaySetUp(this.form);
    },
    // 取消
    cancel() {
      this.dialogFormVisible = false;
      this.configurationList[this.index0].state = this.$i18n.t(
        "front-electronic-signature-isclose"
      );
      this.configurationList[this.index0].value = false;
      this.getEmailOnlinePaySetUp();
    },
  },
  created() {
    this.getEmailOnlinePaySetUp();
  },
};
</script>

<style lang="scss" scoped>
/* 修改label字体大小 */
::v-deep .el-form-item__label {
  font-size: 16px;
  color: #666666;
}
/* 修改label红星大小 */
::v-deep .el-form-item__label:before {
  font-size: 12px;
}
.inline-pay {
  .switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 330px 0 67px;
    height: 128px;
    background: #f3f8fe;
    margin: 24px;
    margin-bottom: 0;
    border-left: 2px solid #006dd3;
    .switchL {
      display: flex;
      align-items: center;
      .zaixianzhifu {
        width: 89px;
        height: 79px;
        margin-right: 69px;
      }
      & > div {
        span {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
        }
        p {
          font-size: 16px;
          color: #333333;
          margin-top: 12px;
        }
      }
    }
    .switchR {
      span {
        margin-right: 12px;
      }
      ::v-deep .el-switch__core {
        width: 48px !important;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
      }
      ::v-deep .el-switch__core:after {
        width: 18px;
        height: 19px;
      }
      ::v-deep .el-switch.is-checked .el-switch__core::after {
        left: 95%;
      }
    }
  }
  .configuration {
    padding: 24px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    & > div {
      display: flex;
      flex-direction: column;
      & > div {
        display: flex;
      }
      & > span {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        padding-bottom: 16px;
        padding-top: 28px;
        .kehu {
          width: 23px;
          height: 23px;
          margin-right: 10px;
        }
      }
    }
    .configurationItem {
      padding: 20px 24px 35px 36px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 544px;
      height: 217px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      margin: 0 12px 0 0;
      .item-top {
        display: flex;
        justify-content: space-between;
        img {
          height: 42px;
        }
        .item-switch {
          span {
            margin-right: 12px;
          }
        }
      }
      .item-Tips {
      }
      & > .el-button {
        width: 119px;
        font-size: 16px;
        color: #ffffff;
        padding: 16px 0;
        line-height: 0;
        text-align: center;
      }
    }
  }
  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.dialogSvg {
  width: 130px;
  height: 70px;
}
.dialogSvgVisa {
  width: 98px;
  height: 70px;
}

.dialogSvgV {
  width: 100px;
  height: 70px;
}
</style>
